import './App.css';
import { Grid } from '@mui/material';
import { SignedIn, SignedOut, SignInButton, RedirectToUserProfile } from "@clerk/clerk-react";


function Admin() {
  return (
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  sx={{ minHeight: '100vh' }}
>
     <SignedIn>
        <RedirectToUserProfile/>
      </SignedIn>
      <SignedOut>
        <SignInButton />
      </SignedOut>
    </Grid>
  );
}

export default Admin;
