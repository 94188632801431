import './App.css';
import Helmet from "react-helmet";
import { Box, Grid } from '@mui/material';

function App() {
  return (
    <Grid
  container
  spacing={0}
  direction="column"
  alignItems="center"
  justifyContent="center"
  sx={{ minHeight: '100vh' }}
>
      <Box
        display="flex"
        alignItems="center"
        gap={4}
        p={2}
        sx={{ border: '2px solid grey' }}
      >
        <div id="getWaitlistContainer" data-waitlist_id="17358" data-widget_type="WIDGET_1"></div>
        <Helmet>
          <link rel="stylesheet" type="text/css" href="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.css"/>
          <script src="https://prod-waitlist-widget.s3.us-east-2.amazonaws.com/getwaitlist.min.js"></script>
        </Helmet>
      </Box>
    </Grid>
  );
}

export default App;
